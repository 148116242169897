import { getPathSimple } from '@/script/logic/translation/get-path-simple';
import { CustomizationCfg } from '@/script/logic/customization/customization-cfg';
import { NodeAnalyzer } from '@/script/logic/extraction/node-analyzer';
import { ScriptRendererConfigV1 } from '@/shared';
import { CustomizationBase } from '@/script/logic/customization/customization-base';

export class SysmexCustomization extends CustomizationBase {
	constructor(private cfg: CustomizationCfg) {
		super();
	}

	getPageSwitchDelay(): number {
		return null;
	}

	isIgnored(): boolean {
		return false;
	}


	allowToCleanupPages(): boolean {
		return true;
	}

	shouldIgnoreTask(): boolean {
		return false;
	}

	public selectLocaleByUrl(location: string, locales: string[]): string | null {
		const detected = this.detectLocaleByUrl(location);

		if (!detected) {
			return null;
		}
		return locales.find((x) => x.toLowerCase() === detected.toLowerCase()) ||
			locales.find((x) => x.split('-')[0] === detected.split('-')[0]);
	}

	public tryGetCustomConfigForSection(sectionId: string): ScriptRendererConfigV1 | null {
		const element = NodeAnalyzer.findElementBySectionAttribute(sectionId);

		if (!element) {
			return null;
		}

		const customLang = NodeAnalyzer.tryGetExplicitlySetLang(element);
		if (!customLang) {
			return null;
		}
		const customConfigs = (window as any).scCustomConfigs as ScriptRendererConfigV1[];
		if (!customConfigs) {
			return null;
		}
		const customLocale = customLang.split('-')[0];
		return customConfigs.find((x) => x.sourceLocale.split('-')[0] === customLocale) || null;
	}

	public hasAdditionalLangLinks(): boolean {
		return false;
	}

	public canChangeLocale(): boolean {
		return true;
	}

	public canDetectLocale(): boolean {
		return true;
	}

	public changeLocale(newLocale: string): void {
		const newLocation = this.getNewLocation(window.location.href, newLocale);
		window.location.href = newLocation;
	}

	public getUrlForLocale(url: string, newLocale: string): string {
		const newLocation = this.getNewLocation(url, newLocale);
		return newLocation;
	}

	public canPatchProxyUrl(): boolean {
		return true;
	}

	public patchProxyUrl(proxyUrl: string, lang: string): string {
		const canonicalLocale = this.mapLocaleToCanonical(lang);
		const urlLocale = this.detectLocaleByUrl(proxyUrl);

		if (urlLocale && canonicalLocale && urlLocale.split('-')[0].toLowerCase() === canonicalLocale.split('-')[0].toLowerCase()) {
			return proxyUrl;
		}

		return this.getNewLocation(proxyUrl, canonicalLocale);
	}

	public getNewLocation(location: string, newLocale: string): string {
		newLocale = this.mapLocaleToCanonical(newLocale.toLowerCase());
		const url = new URL(location);
		const path = url.pathname;
		const firstSegment = path.split('/')[1];
		let newPath = '';
		if (this.getLocale(firstSegment)) {
			const pathSegments = path.replace(/^\//, '').split('/');
			pathSegments.splice(0, 1);
			newPath = '/' + newLocale + '/' + pathSegments.join('/');
		} else {
			newPath = `/${newLocale}${path}`;
		}
		url.pathname = newPath;
		return url.toString();
	}

	public isLocale(locale: string): boolean {
		return this.getLocale(locale) != null;
	}

	public getLocale(segment: string): string {
		if (!this.cfg?.langs) {
			return null;
		}
		const language = segment;
		const foundByFullLanguage = this.cfg.langs.find(
			(l) => l.toLowerCase().toLowerCase() == language?.toLowerCase(),
		);
		if (foundByFullLanguage) {
			return foundByFullLanguage;
		}

		const languageTrimmed = segment.split('-')[0];
		const foundByTrimmedLanguage = this.cfg.langs.find(
			(l) => l.toLowerCase().split('-')[0]?.toLowerCase() == languageTrimmed?.toLowerCase(),
		);
		return foundByTrimmedLanguage;
	}

	public detectLocaleByUrl(location: string): string {
		const url = new URL(location);
		const path = url.pathname;
		const firstSegment = path.split('/')[1];
		const locale = this.getLocale(firstSegment);
		if (locale) {
			return locale;
		}
		return null;
	}

	public canGetPath(): boolean {
		return true;
	}

	public getPath(pageUrl: string): string {
		const pathSimple = getPathSimple(pageUrl);
		const segments = pathSimple.split('/');
		if (!this.getLocale(segments[0])) {
			return pathSimple;
		}
		if (segments.length == 1) {
			return '__index';
		}
		segments.splice(0, 1);
		return segments.join('/');
	}

	public shouldDisableScriptForPage(): boolean {
		return window?.location?.search?.toLowerCase().includes('sc_mode=preview')
	}

	public shouldHideCrawlerButton(): boolean {
		return true;
	}

	private mapLocaleToCanonical(lang: string) {
		const mainLocale = lang.split('-')[0].toLowerCase();
		switch (mainLocale) {
			case 'fr':
				return 'fr-ca';
			case 'pt':
				return 'pt-br';
			case 'en':
				return 'en-jm';
			case 'es':
				return 'es-mx';
		}
		return lang;
	}
}
