<template>
	<div>
		<WtPopup
			class="settings-popup"
			:form-width="900"
			:close-button="true"
			@close="onCancel"
		>
			<template #body>
				<WtVerticalTabs
					v-slot="slotProps"
					title="Site settings"
					:tabs="['Translation settings', 'Language selector', 'Preview settings', 'Publish settings']"
				>
					<div class="settings-popup__tab">
						<div v-if="slotProps.activeTab === 0">
							<div class="settings-path-rules">
								<div class="translation-settings-label">
									<WtTitle class="wt-group-title">Translated page paths</WtTitle>
								</div>
								<div class="settings-path-rules-desc">
									Specify which paths on your website should be translated. Leave this field empty to
									use the domain you entered when creating the translation project.
									Use patterns like <i>/translated-page/*</i> to include paths or specify exact domains.
									To translate a specific page, enter its full URL (e.g., https://website.com/page).
									Add "-" before an entry to exclude specific pages or paths, for example: <i>-/admin/pages/*</i>
								</div>
								<ScTextarea :modelValue="pathRulesToTranslate" @update:modelValue="updatePathRules"/>
							</div>
							<div class="settings-modes">
								<div class="translation-settings-label">
									<WtTitle class="wt-group-title">Translation modes</WtTitle>
								</div>
								<div>
									<WtRadio
										v-for="(v, i) in translationModes"
										:key="v.name"
										class="mode-option"
										name="translation-mode"
										:checked="isTranslationModeChecked(i)"
										@input="onTranslationModeChange(i)"
									>
										<b>{{ v.name }}</b
										><br/><span>{{ v.text }}</span>
									</WtRadio>
								</div>
							</div>
							<div class="manager-settings_option">
								<WtCheckbox
									v-model="internalSettings.general.autoExportFragments"
									name="use-canonical-links"
								>
									Automatically send pages for translation to Smartcat
								</WtCheckbox>
							</div>
						</div>
						<div
							v-if="slotProps.activeTab === 1"
							class="selector-settings"
						>
							<WtTitle class="wt-group-title"> Position</WtTitle>
							<div class="lang-selector-settings">
								<label
									v-for="(loc, i) in selectorLocations"
									:key="loc.value"
									:class="getSelectorOptionClass(i)"
								>
									<input
										type="radio"
										name="lang-selector"
										:checked="isSelectorOptionChecked(i)"
										@change="onSelectorPositionChanged(i)"
									/>
									<span class="lang-selector-grid">
										<span/><span/><span/> <span/><span/><span/> <span/><span/><span/>
									</span>
									<span class="lang-selector-option__label">
										{{ loc.label }}
									</span>
								</label>
							</div>
							<WtTitle
								v-if="internalSettings.general.languageSelectorLocation !== 'None'"
								class="wt-group-title"
							>
								Offsets
							</WtTitle>
							<div
								v-if="internalSettings.general.languageSelectorLocation !== 'None'"
								class="lang-selector-offsets"
							>
								<div
									v-if="internalSettings.general.languageSelectorLocation === 'BottomRight'"
									class="input-group"
								>
									<label for="bottom-offset">Bottom</label>
									<div class="field">
										<input
											id="bottom-offset"
											v-model="internalSettings.general.languageSelectorOffsets.bottom"
											type="number"
											@input="onLangSelectorSettingsChanged"
										/>
									</div>
								</div>
								<div
									v-if="internalSettings.general.languageSelectorLocation === 'TopRight'"
									class="input-group"
								>
									<label for="bottom-offset">Top</label>
									<div class="field">
										<input
											id="bottom-offset"
											v-model="internalSettings.general.languageSelectorOffsets.top"
											type="number"
											@input="onLangSelectorSettingsChanged"
										/>
									</div>
								</div>
								<div class="input-group">
									<label for="bottom-offset">Right</label>
									<div class="field">
										<input
											id="bottom-offset"
											v-model="internalSettings.general.languageSelectorOffsets.right"
											type="number"
											@input="onLangSelectorSettingsChanged"
										/>
									</div>
								</div>
							</div>
						</div>
						<div
							v-if="slotProps.activeTab === 2"
							class="manager-settings"
						>
							<div class="manager-settings_option">
								<WtCheckbox
									v-model="internalSettings.preview.autoTranslateInPreview"
									name="lang-selector"
								>
									Auto-start page translation in preview mode
								</WtCheckbox>
							</div>
							<div class="manager-settings_option">
								<WtCheckbox
									v-model="internalSettings.general.useCanonicalLinks"
									name="use-canonical-links"
								>
									Use canonical links if available
								</WtCheckbox>
							</div>
							<div class="manager-settings_option">
								<WtCheckbox
									v-model="internalSettings.general.disableLanguageAddition"
									name="use-canonical-links"
								>
									Lock the list of project languages
								</WtCheckbox>
							</div>
						</div>
						<div v-if="slotProps.activeTab === 3">
							<div class="manager-settings">
								<WtTitle class="wt-group-title"> Published Languages</WtTitle>
								<div
									v-for="option in publishedLanguageOptions"
									:key="option.cultureName"
									class="manager-settings_option"
								>
									<WtCheckbox
										v-model="option.isSelected"
										name="lang-selector"
									>
										{{ option.languageName }}
									</WtCheckbox>
								</div>
								<br/>
								<br/>
								<div v-if="publishedScriptToShow">
									<WtText>
										To publish translations on your website, add the following JavaScript code
										<br/>
										<b>to the header</b> of your website:
									</WtText>
									<div>
										<WtCode
											:code="publishedScriptToShow"
											@copy="copyToClipboard"
										/>
									</div>
								</div>
								<div class="manager-settings_option"
								>
									<WtCheckbox
										v-model="internalSettings.publishing.loadTranslationsAsynchronously"
									>
										Load translations without delaying page content
									</WtCheckbox>
								</div>

							</div>
						</div>
					</div>
					<div class="settings-popup__footer">
						<WtButton
							label="Cancel"
							view="simple"
							@click="onCancel"
						/>
						<WtButton
							label="Save changes"
							:disabled="!hasChanges"
							:async-action="onSave"
						/>
					</div>
				</WtVerticalTabs>
			</template>
		</WtPopup>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import WtPopup from '@/components/widgets/wt-popup/wt-popup.vue';
	import WtTitle from '@/components/widgets/wt-title/wt-title.vue';
	import WtText from '@/components/widgets/wt-text/wt-text.vue';
	import WtCode from '@/components/widgets/wt-code/wt-code.vue';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';
	import WtRadio from '@/components/widgets/wt-radio/wt-radio.vue';
	import { ScNotification, ScLabel, ScTextarea, ScTooltip, ScIcon, ScText } from '@smartcat/design-system-vue2';
	import { LanguageSelectorLocation, PublishLangSettings, WebSiteSettingsDto } from '@/shared';
	import WtSwitch from '@/components/widgets/wt-switch/wt-switch.vue';
	import WtTabs from '@/components/widgets/wt-tab/wt-tabs.vue';
	import WtCheckbox from '@/components/widgets/wt-checkbox/wt-checkbox.vue';
	import { TranslatedFrameService } from '@/services/translated-frame-service';
	import { inject } from '@/components/common/di';
	import { LanguagesStore } from '@/components/widgets/languages/language-store';
	import { PublishedLanguageOption } from '@/components/widgets/settings-popup/publishedLanguageOption';
	import WtVerticalTabs from '@/components/widgets/wt-vertical-tabs/wt-vertical-tabs.vue';
	import { NotificationService } from '@/components/widgets/notifications/notificationService';
	import { deepClone } from '@/utils/object';

	@Component({
		components: {
			WtVerticalTabs,
			WtCheckbox,
			WtTabs,
			WtSwitch,
			WtPopup,
			WtTitle,
			WtText,
			WtCode,
			WtButton,
			ScNotification,
			WtRadio,
			ScLabel,
			ScTextarea,
			ScTooltip,
			ScIcon,
			ScText
		},
	})
	export default class ScriptPopup extends Vue {
		public publishedLanguageOptions: PublishedLanguageOption[] = [];

		@Prop({ type: Object, required: true })
		public readonly translatedFrameService: TranslatedFrameService;

		@Prop({ type: Object, required: true })
		public readonly settings: WebSiteSettingsDto;

		@Prop({ type: Array, required: true })
		public readonly targetLanguages: string[];

		@Prop({ type: String, required: false })
		public readonly publishedScriptToShow: string;

		public internalSettings: WebSiteSettingsDto = {} as any;

		public selectorLocations = [
			{ className: 'selector-bottom-right', value: LanguageSelectorLocation.BottomRight, label: 'Bottom' },
			{ className: 'selector-top-right', value: LanguageSelectorLocation.TopRight, label: 'Top' },
			{ className: 'selector-disabled', value: LanguageSelectorLocation.None, label: 'None' },
		];

		public pathRulesToTranslate = '';

		public translationModes = [
			{
				name: 'Show available translations only',
				text:
					'Website visitors will only see languages available for each particular page, and will be redirected to the appropriate language based on browser preferences. If a page has new content, it will appear untranslated unless you as a manager updated translations in Smartcat.\n' +
					'\n' +
					'In this mode, Smartwords will not be consumed by Website Translator unless you explicitly translate page in Smartcat.',
				value: { autoDetectUserLanguage: true, translateNewElements: false },
			},
			{
				name: 'Auto-translate website pages based on preferred language',
				text:
					'When visiting any page of your site, Website Translator will show all languages defined in your Smartcat project as available. Visitors will be redirected to the appropriate language based on browser preferences, at which point automatic translation will happen for all new page content.\n' +
					'\n' +
					'The first time we encounter new content and perform translation, we will automatically charge Smartwords from your balance.',
				value: { autoDetectUserLanguage: true, translateNewElements: true },
			},
			{
				name: 'Translate any page on explicit language selection',
				text:
					'Same as above, but website visitors will not be automatically redirected to a matching language; instead, they will need to select the needed language from the provided language selection widget. If a page has new content, it will be translated automatically.\n' +
					'\n' +
					'This mode offers the convenience of a fully automated translation mode, but Smartwords will be used more conservatively, only when someone explicitly requests translation.',
				value: { autoDetectUserLanguage: false, translateNewElements: true },
			},
		];

		private initialSettings = '';

		public get hasChanges() {
			return (
				this.initialSettings !==
				JSON.stringify(this.internalSettings) + JSON.stringify(this.publishedLanguageOptions)
			);
		}

		isTranslationModeChecked(i: number) {
			const { autoDetectUserLanguage, translateNewElements } = this.internalSettings.general;
			return (
				this.translationModes[i].value.autoDetectUserLanguage === autoDetectUserLanguage &&
				this.translationModes[i].value.translateNewElements === translateNewElements
			);
		}

		isSelectorOptionChecked(i: number) {
			return this.selectorLocations[i].value === this.internalSettings.general.languageSelectorLocation;
		}

		getSelectorOptionClass(i: number) {
			const item = this.selectorLocations[i];

			return {
				'lang-selector-option': true,
				[item.className]: true,
				'selected': this.isSelectorOptionChecked(i),
			};
		}

		created() {
			this._languagesStore = inject(LanguagesStore);
			this.internalSettings = deepClone(this.settings);
			this.initialSettings = JSON.stringify(this.internalSettings);
			this.loadPathRulesFromSettings();

			this.$watch(
				() => this.settings,
				(v) => {
					this.internalSettings = deepClone(v);
					this.loadPathRulesFromSettings();
				},
			);

			this.targetLanguages.forEach((locale) => {
				const lang = this._languagesStore.getByCultureName(locale);
				const existingSetting = this.settings.publishing?.langSettings.find((x) => x.lang === locale);
				const isPublished = existingSetting?.publish !== false;
				this.publishedLanguageOptions.push({
					languageName: lang.englishName,
					cultureName: lang.cultureName,
					isSelected: isPublished,
				});
			});

			this.initialSettings =
				JSON.stringify(this.internalSettings) + JSON.stringify(this.publishedLanguageOptions);
		}

		onSelectorPositionChanged(i: number) {
			this.internalSettings.general.languageSelectorLocation = this.selectorLocations[i].value;
			this.onLangSelectorSettingsChanged();
		}

		onTranslationModeChange(i: number) {
			this.internalSettings.general = {
				...this.internalSettings.general,
				...this.translationModes[i].value,
			};
		}

		async copyToClipboard() {
			await navigator.clipboard.writeText(this.publishedScriptToShow);
			this.$emit('copied');
			inject(NotificationService).showSuccess('Script copied');
		}

		onLangSelectorSettingsChanged() {
			this.translatedFrameService.updateLangSelectorConfig({
				location: this.internalSettings.general.languageSelectorLocation,
				offsets: this.internalSettings.general.languageSelectorOffsets,
			});
		}

		public updatePathRules(e: string) {
			this.pathRulesToTranslate = e;
			const str = this.pathRulesToTranslate.trim();
			const pathRules = str ? str.split('\n').map((x) => x.trim()) : [];

			const translationSettings = this.internalSettings.translation ?? {};
			translationSettings.pathRulesToTranslate = pathRules;
			this.internalSettings.translation = translationSettings;
		}

		async onCancel() {
			this.internalSettings = deepClone(this.settings);
			this.$emit('cancel');
		}

		async onSave() {
			const handler = this.$listeners.save as any;
			const settingsDto = deepClone(this.internalSettings);
			for (const langOption of this.publishedLanguageOptions) {
				if (settingsDto.publishing == null) {
					settingsDto.publishing = { loadTranslationsAsynchronously: false };
				}
				if (settingsDto.publishing.langSettings == null) {
					settingsDto.publishing.langSettings = [];
				}
				let langSetting: PublishLangSettings = settingsDto.publishing.langSettings.find(
					(x) => x.lang == langOption.cultureName,
				);
				if (!langSetting) {
					langSetting = { publish: langOption.isSelected, lang: langOption.cultureName };
					const val = langSetting;
					settingsDto.publishing.langSettings.push(val);
				} else {
					langSetting.publish = langOption.isSelected;
				}
			}

			if (handler) {
				await handler(settingsDto);
			}
		}

		private loadPathRulesFromSettings() {
			this.pathRulesToTranslate = this.internalSettings.translation?.pathRulesToTranslate?.join('\n') ?? '';
		}

		_languagesStore: LanguagesStore;
	}
</script>
<style lang="less" src="./settings-popup.less" scoped/>
