import { ISectionInfo } from '@/script/logic/extraction/section-info';
import { ILangSelector } from '@/script/lang-selector/i-lang-selector';
import { LangSelectorConfig } from '@/script/lang-selector/lang-selector-config';
import { LovisaLangSelector } from '@/script/logic/customization/lovisa-lang-selector';
import { ShopifyCustomizationV2 } from '@/script/logic/customization/shopify-customization-v2';
import { LovisaSectionHandler } from '@/script/logic/customization/lovisa-section-handler';

export class LovisaCustomization extends ShopifyCustomizationV2 {
	private readonly _productJsonSelector = '[data-product-json]';
	private readonly _instaDate = '.fs-entry-date';
	private readonly _strongTag = 'strong[id="0"]';
	private readonly _spanWithTag = 'span[id="1"]';
	private readonly _filterCount = '.filter-item-count, .collection__tab--results-no, .mobile-totalCount, .hide-filter-space';
	private readonly _reviewsCount = '.reviews-count';
	private readonly _cartPrice = '.cart__totals-price, .side-cart__subtotal, .side-cart-form__total-price, .cart-item__price, .cart__shipping--price';
	private readonly _productPrice = '.product-prices, .upsell-price, s[data-compare-price], span[data-original-price]';

	private readonly _combinedSectionSelector = [
		this._productJsonSelector,
		this._instaDate,
		this._strongTag,
		this._spanWithTag,
		this._filterCount,
		this._reviewsCount,
		this._cartPrice,
		this._productPrice
	].join(', ');

	public createLangSelector(config: LangSelectorConfig): ILangSelector {
		return new LovisaLangSelector(config);
	}

	public override canCreateLangSelector(): boolean {
		return true;
	}

	public isIgnored(node: Node): boolean {
		if (node instanceof Element) {
			const el = node as HTMLElement;
			if (node.matches(this._combinedSectionSelector)) {
				return true;
			}
			if (el.childNodes.length == 1 && el.childNodes[0].nodeType == 3) {
				return this.isInvalidText(node.textContent);
			}
		} else if (node.nodeType === 3) {
			if (node.parentElement.matches(this._combinedSectionSelector)) {
				return true;
			}
			return this.isInvalidText(node.textContent);
		}
		return false;
	}

	public isInvalidText(t: string): boolean {
		return super.isInvalidText(t) || t.toLowerCase().includes('optimizely');
	}

	public findSectionForElement(element: Element, location: string): ISectionInfo | null {
		const lovisa = new LovisaSectionHandler(element, location);
		const sectionId = lovisa.getSectionId();
		if (sectionId) {
			return {
				id: sectionId
			}
		}
	}
}
