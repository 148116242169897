import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import LandingPage from '@/components/landing/landing-page.vue';
import BrowserPage from '@/components/browser/browser-page.vue';
import DemoBrowserPage from '@/components/demo-browser/demo-browser-page.vue';
import DemoPublishingAdminPage from '@/components/publish-demo/demo-publishing-admin-page.vue';
import WidgetsPage from '@/components/widgets/widgets-page/widgets-page.vue';
import ComponentsPage from '@/components/widgets/components-page/components-page.vue';
import ToolbarsPage from '@/components/widgets/toolbars-page/toolbars-page.vue';
import { vitalsClient } from '@smartcat/vitals-client';
import WebSitePageList from '@/components/web-site-pages/web-site-page-list.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'landing',
		component: LandingPage,
	},
	{
		path: '/browser/:webSiteId',
		name: 'Browser',
		component: BrowserPage,
	},
	{
		path: '/demo-browser/:webSiteId',
		name: 'DemoBrowser',
		component: DemoBrowserPage,
	},
	{
		path: '/demo-publish-admin',
		name: 'demo-publishing-admin',
		component: DemoPublishingAdminPage,
	},
	{
		path: '/widgets',
		name: 'widgets',
		component: WidgetsPage,
	},
	{
		path: '/web-site/:webSiteId/pages',
		name: 'WebSitePageList',
		component: WebSitePageList,
	},
];

if (process.env.NODE_ENV === 'development') {
	routes.push(
		...[
			{
				path: '/components',
				name: 'components',
				component: ComponentsPage,
			},
			{
				path: '/toolbars',
				name: 'toolbars',
				component: ToolbarsPage,
			},
		],
	);
}

const router = new VueRouter({
	mode: 'history',
	scrollBehavior: function (to) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			};
		} else {
			return { x: 0, y: 0 };
		}
	},
	routes,
});

router.afterEach((to, from) => {
	const basePath = '/webtranslation';
	vitalsClient.trySendPageLoadCancelled();
	if (to.name !== from.name) {
		// Добавляем basePath в начало, чтобы различать сценарии с основным приложением
		vitalsClient.sendPageLoadStarted(basePath + to.path, basePath + to.fullPath);
	}
});

export default router;
