import { ShopifyBaseSectionHandler } from '@/script/logic/customization/shopify-base-section-handler';
import { sanitize } from '@/script/utils/sanitize';

export class LovisaSectionHandler extends ShopifyBaseSectionHandler {
	private readonly _productHandleSelector = '[data-apz-upsell-product-handle]';

	protected knownWords = new Set([
		'add',
		'select',
		'total',
		'enter-shipping-address',
		'shipping',
		'subtotal',
		'apply-gift-card',
		'apply',
		'discount-code',
		'perfect-picks-to-pair-with',
		'quantity'
	]);

	protected isShoppingCart() {
		return this.isAncestorOrItemMatchSelector('.side-cart, .cart-item__title');
	}

	protected isProductInCart() {
		return this.isAncestorOrItemMatchSelector('.upsell-item');
	}

	protected isCollectionsFilter() {
		return this.isAncestorOrItemMatchSelector('.collection-filters-block');
	}

	protected isCollectionsSorting() {
		return this.isAncestorOrItemMatchSelector('.collection-sorting');
	}

	protected isProductCard() {
		return this.isAncestorOrItemMatchSelector('.product-card')
			|| this.isAncestorOrItemMatchSelector('.apz_product_item');
	}

	protected isProductCardTexts() {
		return this.isAncestorOrItemMatchSelector('.am_rec_add_to_cart_button')
			|| this.isAncestorOrItemMatchSelector('.upsell-item__add-to-bag')
			|| this.isAncestorOrItemMatchSelector('.product-card__badge')
			|| this.isAncestorOrItemMatchSelector('.upsell-item__variants')
			|| this.isAncestorOrItemMatchSelector('.upsell-item__add-to-bag--mobile')
			|| this.isAncestorOrItemMatchSelector('.upsell-item__variants--mobile')
			|| this.isAncestorOrItemMatchSelector('.product-card__actions')
			|| this.isAncestorOrItemMatchSelector('.product-card__price')
			|| this.isAncestorOrItemMatchSelector('.pd-reviews-block')
			|| this.isAncestorOrItemMatchSelector('.okeReviews')
			|| this.isAncestorOrItemMatchSelector('.reviews-rating-count-wrapper');
	}

	protected isCountrySelector() {
		return this.isAncestorOrItemMatchSelector('.region-currency-modal')
			|| this.isAncestorOrItemMatchSelector('.region-auto-location-modal');
	}

	protected isInstagramSection() {
		return this.isAncestorOrItemMatchSelector('.instagram');
	}

	protected isInstagramDialog() {
		return this.isAncestorOrItemMatchSelector('.fs-detail-container');
	}

	protected isShoppingCartText() {
		return this.isAncestorOrItemMatchSelector('.cart-item__options');
	}

	protected isProductSection() {
		return super.isProductSection() || this.isAncestorOrItemMatchSelector('#tab-pd');
	}

	public getSectionId() {
		if (this.isShoppingCartText()) {
			return 'cart';
		}
		if (this.isProductCardTexts()) {
			return 'product'
		}
		if (this.isProductCard()) {
			const selector = '.apz_product_item, .product-card'
			const productCard = this.getProductCardSectionBySelector(selector);
			if (productCard) {
				return productCard;
			}
			const productCardBySection = this.getProductCardSectionByAttribute(selector, 'data-apz-upsell-product-handle');
			if (productCardBySection) {
				return productCardBySection;
			}
			return 'product'
		}
		if (this.isHeader()) {
			return 'header';
		}
		if (this.isFooter()) {
			return 'footer';
		}
		if (this.isCountrySelector()) {
			return 'country-select'
		}
		if (this.isProductInCart()) {
			return this.getProductInCartSection('.upsell-item');
		}
		if (this.isShoppingCart()) {
			return this.getShoppingCartSection('.cart-item, .cart-item__title');
		}
		if (this.isCollectionsFilter()) {
			return 'collections-filter'
		}
		if (this.isCollectionsSorting()) {
			return 'collections-sorting'
		}
		if (this.isInstagramSection() || this.isInstagramDialog()) {
			return 'instagram'
		}
		if (this.isCollections()) {
			return this.getCollectionsSection();
		}
		if (this.isProductsPage()) {
			return this.getProductSection();
		}
		if (this.isCheckouts()) {
			return this.getCheckoutSection();
		}
		if (this.isBlogsPage()) {
			return 'blogs-page';
		}

		const productHandle = this.el.closest(this._productHandleSelector);
		if (productHandle) {
			const val = productHandle.getAttribute('data-apz-upsell-product-handle');
			if (val) {
				return 'dynamic/product/' + sanitize(val);
			}
		}
	}
}
